import { Helmet } from 'react-helmet-async';

export default function Head() {
  return (
    <Helmet>
      <title>Color Cube | E-catalog</title>

      {/* Fonts */}
      <link
        rel='preconnect'
        href='https://fonts.googleapis.com'
      />
      <link
        rel='preconnect'
        href='https://fonts.gstatic.com'
        crossorigin
      />
      <link
        href='https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700;800;900&display=swap'
        rel='stylesheet'
      />
      <link
        rel='stylesheet'
        type='text/css'
        href='https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard-dynamic-subset.css'
      />
    </Helmet>
  );
}
