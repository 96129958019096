import 'styles/default.css';
import 'styles/custom.css';
import * as CONFIG from 'config';
import { catalogOptions } from 'config/catalogOptions';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { CatalogProvider } from 'Catalog/context';
import Head from 'Head';
import Frame from 'Catalog/Frame';
import ScrollTop from 'Catalog/ScrollTop';

function Catalog() {
  return (
    <CatalogProvider {...catalogOptions}>
      <ScrollTop>
        <Frame />
      </ScrollTop>
    </CatalogProvider>
  );
}

export default function App() {
  return (
    <HelmetProvider>
      <Head />
      <BrowserRouter basename={`${CONFIG.BASE_NAME}`}>
        <Routes>
          <Route
            path='/'
            element={<Catalog />}
          />

          <Route
            path='/:lang'
            element={<Catalog />}
          />
          <Route
            path='/:lang/:depth1'
            element={<Catalog />}
          />
          <Route
            path='/:lang/:depth1/:depth2'
            element={<Catalog />}
          />
          <Route
            path='*'
            element={<Catalog />}
          />
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  );
}
