import styles from './Frame.module.css';
import classnames from 'classnames/bind';
import { Suspense, useEffect, useState, useContext, lazy } from 'react';
import { CatalogContext } from './context';
import Navigations from 'Component/Navigations';

const cx = classnames.bind(styles);

export default function Frame() {
  const { lang, depth1, pageMap, currentPageData } = useContext(CatalogContext);

  const [Pages, setPages] = useState([]);
  useEffect(() => {
    setPages(prev => {
      const newArr = [];
      pageMap.forEach(item => {
        newArr.push(lazy(() => import(`/src/Pages/${item}`)));
      });
      return newArr;
    });
  }, [pageMap]);

  const setCurrentClasses = () => {
    const classes = [`ct-page--${depth1}`, `ct-lang--${lang}`];

    for (const key in currentPageData) {
      const value = currentPageData[key];
      const type = typeof value;

      if (type !== 'string' && type !== 'number') continue;

      classes.push(`ct-${key}--${value}`);
    }

    return classes.join(' ');
  };

  return (
    <div className={styles.window}>
      <Scaler className={`${styles.container} ${setCurrentClasses()}`}>
        <div className={styles.wrapper}>
          {Pages &&
            Pages.map((Page, i) => (
              <div
                key={`ct-page--key-${i}`}
                className={`${cx('page', `${i === depth1 - 1 ? 'page--active' : ''}`)} ${
                  i === depth1 - 1 ? 'pg--active' : ''
                } ${i === depth1 - 2 ? 'pg--prev' : ''} ${
                  i === depth1 ? 'pg--next' : ''
                }`}
              >
                <Suspense>
                  <Page
                    isActive={i === depth1 - 1}
                    isPrev={i === depth1 - 2}
                    isNext={i === depth1}
                  />
                </Suspense>
              </div>
            ))}
        </div>
        <Navigations />
      </Scaler>
    </div>
  );
}

function Scaler({ children, className }) {
  const { width, height, breakpoint } = useContext(CatalogContext);
  const getScale = () => {
    const deltaRatio = width / height;
    const windowRatio = window.innerWidth / window.innerHeight;

    return windowRatio <= deltaRatio
      ? window.innerWidth / width
      : window.innerHeight / height;
  };
  const getStyle = scaleRatio => {
    let style = {};

    if (window.innerWidth > breakpoint) {
      style = {
        '--width': `${width}px`,
        '--height': `${height}px`,
        transform: `scale(${scaleRatio})`,
      };
    } else {
      style = {
        '--width': `100%`,
        '--height': `auto`,
        transform: `none`,
      };
    }

    return style;
  };

  const [scaleRatio, setScaleRatio] = useState(getScale());
  useEffect(() => {
    const resizeHandler = () => setScaleRatio(getScale());
    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  const [style, setStyle] = useState(getStyle(scaleRatio));
  useEffect(() => {
    setStyle(getStyle(scaleRatio));
  }, [scaleRatio]);

  return (
    <div
      className={className}
      style={style}
    >
      {children}
    </div>
  );
}
