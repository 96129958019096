export const catalogOptions = {
  langSet: ['ko', 'en'],
  defaultLang: 'ko',
  pageMap: [
    'CatalogCover',
    'CatalogIndex',
    'AluminumIntro',
    'Aluminum-About',
    'Aluminum-Strength',
    'Aluminum-Tech1',
    'Aluminum-Tech2',
    'Aluminum-Tech3',
    'Aluminum-Cert1',
    'Aluminum-Cert2',
    'Aluminum-Fasner1',
    'Aluminum-Fasner2',
    'Aluminum-Wire1',
    'Aluminum-Wire2',
    'Aluminum-Wire3',
    'Aluminum-App1',
    'Aluminum-App2',
    'CompanyIntro',
    'Company-Greetings',
    'Company-RND',
    'Company-History',
    'CatalogLast',
  ],
  width: 1920,
  height: 930,
  breakpoint: 768,
  autoplay: {
    delay: 4000,
    loop: true,
  },
};
