import { createContext } from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const CatalogContext = createContext();

export function CatalogProvider({
  children,
  width = 1920,
  height = 930,
  breakpoint = 768,
  pageMap,
  langSet = ['ko', 'en'],
  defaultLang = langSet[0],
  autoplay = {
    delay: 7000,
    loop: true,
  },
}) {
  const navigate = useNavigate();
  const params = useParams();
  const pageTotal = pageMap.length;
  const lang = params.lang;
  const depth1 = parseInt(params.depth1, 10);
  const depth2 = parseInt(params.depth2, 10);
  const path = { prev: null, next: null };

  useEffect(() => {
    if (!lang || lang === 'undefined' || !langSet.includes(lang)) {
      navigate(`/${defaultLang}/1`);
    } else if (!depth1 || depth1 <= 0) {
      navigate(`/${lang}/1`);
    } else if (depth1 > pageTotal) {
      navigate(`/${lang}/${pageTotal}`);
    }
  }, [defaultLang]);

  if (depth1 > 1) {
    path.prev = `/${lang}/${depth1 - 1}`;
  } else {
    path.prev = null;
  }
  if (depth1 < pageTotal) {
    path.next = `/${lang}/${depth1 + 1}`;
  } else {
    path.next = null;
  }

  const [currentPageData, setCurrentPageData] = useState(null);
  const [pagingDirection, setPagingDirection] = useState(null);

  const paging = dir => {
    switch (dir) {
      case 'prev':
        if (!path.prev) {
          console.log('no prev page');
          return;
        }
        navigate(path.prev);
        setPagingDirection('prev');
        break;
      case 'next':
        if (!path.next) {
          console.log('no next page');
          return;
        }
        navigate(path.next);
        setPagingDirection('next');
        break;
      default:
        if (typeof dir !== 'number') {
          throw new Error('dir is NaN');
        }
        break;
    }
  };

  const keydownHandler = e => {
    const key = e.key;
    const directionMap = {
      ArrowLeft: 'prev',
      ArrowUp: 'prev',
      ArrowRight: 'next',
      ArrowDown: 'next',
    };

    const direction = directionMap[key];
    if (direction) {
      paging(direction);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', keydownHandler);

    return () => {
      window.removeEventListener('keydown', keydownHandler);
    };
  }, [path]);

  return (
    <CatalogContext.Provider
      value={{
        lang,
        width,
        height,
        breakpoint,
        pageMap,
        depth1,
        depth2,
        path,
        pageTotal,
        pagingDirection,
        currentPageData,
        setCurrentPageData,
        autoplay,
      }}
    >
      {children}
    </CatalogContext.Provider>
  );
}
