import { Link } from 'react-router-dom';
import { useContext, useEffect, useRef, useState } from 'react';
import { CatalogContext } from './context';
import { useNavigate } from 'react-router-dom';

export function CatalogLink({ children, to, type = 'relative', target, className }) {
  const { lang } = useContext(CatalogContext);
  if (type === 'relative') {
    to = `/${lang}/${to}`;
  } else {
    to = to;
  }
  return (
    <Link
      to={to}
      target={target}
      className={className}
    >
      {children}
    </Link>
  );
}

export function PrevButton({ children, className }) {
  const to = useContext(CatalogContext).path.prev;
  return (
    <Link
      to={to}
      className={className}
    >
      {children}
    </Link>
  );
}

export function NextButton({ children, className }) {
  const to = useContext(CatalogContext).path.next;
  return (
    <Link
      to={to}
      className={className}
    >
      {children}
    </Link>
  );
}

export function AutoplayButton({ className }) {
  const navigate = useNavigate();
  const buttonRef = useRef();
  const { path, lang, autoplay } = useContext(CatalogContext);
  const [autoplayState, setAutoplayState] = useState(false);
  const [autoplayTimer, setAutoplayTimer] = useState(undefined);

  const handleClick = e => {
    if (e.target !== buttonRef.current) {
      clearTimeout(autoplayTimer);
      setAutoplayState(false);
    }
  };
  const handleKeydown = e => {
    clearTimeout(autoplayTimer);
    setAutoplayState(false);
  };

  useEffect(() => {
    if (autoplayState) {
      const timer = setTimeout(() => {
        if (!path.next) {
          navigate(`/${lang}/${1}`);
        } else {
          navigate(path.next);
        }
      }, autoplay.delay);
      setAutoplayTimer(timer);
    } else {
      clearTimeout(autoplayTimer);
    }
    return () => {
      clearTimeout(autoplayTimer);
    };
  }, [autoplayState, path]);

  useEffect(() => {
    if (!autoplayState) {
      clearTimeout(autoplayTimer);
    }
  }, [autoplayState, autoplayTimer]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeydown);
    window.addEventListener('click', handleClick);

    return () => {
      window.removeEventListener('click', handleClick);
      window.removeEventListener('keydown', handleKeydown);
    };
  }, []);

  return (
    <button
      ref={buttonRef}
      className={`${className} ${autoplayState ? 'play' : ''}`}
      onClick={() => setAutoplayState(prev => !prev)}
    ></button>
  );
}
