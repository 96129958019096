import * as CONFIG from '../config';
import styles from './Navigations.module.css';
import { useContext } from 'react';
import { CatalogContext } from '../Catalog/context';
import { CatalogLink, PrevButton, NextButton, AutoplayButton } from '../Catalog/Buttons';

function Header() {
  const { currentPageData, lang, setAutoplayState, autoplayState, depth1 } =
    useContext(CatalogContext);
  if (!currentPageData || !lang) {
    return null;
  }

  if (lang === 'ko') {
    return (
      <header
        className={`${styles.header} ${
          currentPageData.headerState === 'show'
            ? styles.header__show
            : styles.header__hide
        } ${styles[`theme--${currentPageData.headerTheme}`]}`}
      >
        <h1 className={styles.logo}>
          <CatalogLink to='1'>
            <img
              src={`${CONFIG.IMG_URL}/common/logo-color.png`}
              alt='Color Cube: Super Material Aluminum'
              className={styles.color}
            />
            <img
              src={`${CONFIG.IMG_URL}/common/logo-white.png`}
              alt='Color Cube: Super Material Aluminum'
            />
          </CatalogLink>
        </h1>
        <ul className={styles.gnb}>
          <li
            className={`${styles.gnb__item} ${
              currentPageData.depth1 === 'aluminum' ? styles.active : ''
            }`}
          >
            <CatalogLink to='3'>Super aluminum</CatalogLink>
            <div className={styles.submenu}>
              <ul className={styles.depth1}>
                <li className={styles.depth1__item}>
                  <CatalogLink to='4'>소개</CatalogLink>
                </li>
                <li className={styles.depth1__item}>
                  <CatalogLink to='5'>핵심 역량</CatalogLink>
                </li>
                <li className={styles.depth1__item}>
                  <CatalogLink to='6'>technology</CatalogLink>
                  <ul className={styles.depth2}>
                    <li>
                      <CatalogLink to='6'>데이터시트</CatalogLink>
                    </li>
                    <li>
                      <CatalogLink to='7'>소재 비교</CatalogLink>
                    </li>
                    <li>
                      <CatalogLink to='9'>성적 증명서</CatalogLink>
                    </li>
                  </ul>
                </li>
                <li className={styles.depth1__item}>
                  <CatalogLink to='11'>슈퍼알루미늄 응용제품</CatalogLink>
                  <ul className={styles.depth2}>
                    <li>
                      <CatalogLink to='11'>알루미늄 화스너</CatalogLink>
                    </li>
                    <li>
                      <CatalogLink to='13'>금속 3D 프린팅 와이어</CatalogLink>
                    </li>
                  </ul>
                </li>
                <li className={styles.depth1__item}>
                  <CatalogLink to='16'>활용 범위</CatalogLink>
                  <ul className={styles.depth2}>
                    <li>
                      <CatalogLink to='16'>소재의 활용의 유연성</CatalogLink>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </li>
          <li
            className={`${styles.gnb__item} ${
              currentPageData.depth1 === 'company' ? styles.active : ''
            }`}
          >
            <CatalogLink to='18'>Company</CatalogLink>
            <div className={styles.submenu}>
              <ul className={styles.depth1}>
                <li className={styles.depth1__item}>
                  <CatalogLink to='19'>인사말</CatalogLink>
                </li>
                <li className={styles.depth1__item}>
                  <CatalogLink to='20'>연구소 소개</CatalogLink>
                </li>
                <li className={styles.depth1__item}>
                  <CatalogLink to='21'>주요 연혁</CatalogLink>
                </li>
              </ul>
            </div>
          </li>
        </ul>
        <nav className={styles.header__etc}>
          <a
            href='https://colorcube.kr/default/mp1/mp1_sub3.php?sub=05'
            target='_blank'
            className={styles.inq_btn}
          >
            <span>
              <span className={styles.m_hide}>온라인</span> 문의
            </span>
          </a>
          <ul className={styles.lang}>
            <li className={styles.active}>
              <CatalogLink
                to={`/ko/${depth1}`}
                type='absolute'
              >
                KOR
              </CatalogLink>
            </li>
            <li>
              <CatalogLink
                to={`/en/${depth1}`}
                type='absolute'
              >
                ENG
              </CatalogLink>
            </li>
          </ul>
          <div className={styles.ctrs}>
            <CatalogLink
              to='2'
              className={`${styles.ctrs__btn} ${styles.ctrs__idx}`}
            >
              <div className={styles.bar}></div>
              <div className={styles.bar}></div>
              <div className={styles.bar}></div>
            </CatalogLink>
            <AutoplayButton className={`${styles.ctrs__btn} ${styles.ctrs__autoplay}`} />
          </div>
        </nav>
      </header>
    );
  } else {
    return (
      <header
        className={`${styles.header} ${
          currentPageData.headerState === 'show'
            ? styles.header__show
            : styles.header__hide
        } ${styles[`theme--${currentPageData.headerTheme}`]}`}
      >
        <h1 className={styles.logo}>
          <CatalogLink to='1'>
            <img
              src={`${CONFIG.IMG_URL}/common/logo-color.png`}
              alt='Color Cube: Super Material Aluminum'
              className={styles.color}
            />
            <img
              src={`${CONFIG.IMG_URL}/common/logo-white.png`}
              alt='Color Cube: Super Material Aluminum'
            />
          </CatalogLink>
        </h1>
        <ul className={styles.gnb}>
          <li
            className={`${styles.gnb__item} ${
              currentPageData.depth1 === 'aluminum' ? styles.active : ''
            }`}
          >
            <CatalogLink to='3'>Super aluminum</CatalogLink>
            <div className={styles.submenu}>
              <ul className={styles.depth1}>
                <li className={styles.depth1__item}>
                  <CatalogLink to='4'>INTRODUCTION</CatalogLink>
                </li>
                <li className={styles.depth1__item}>
                  <CatalogLink to='5'>CORE CAPABILITIES</CatalogLink>
                </li>
                <li className={styles.depth1__item}>
                  <CatalogLink to='6'>technology</CatalogLink>
                  <ul className={styles.depth2}>
                    <li>
                      <CatalogLink to='6'>Data Sheet</CatalogLink>
                    </li>
                    <li>
                      <CatalogLink to='7'>Material Comparison</CatalogLink>
                    </li>
                    <li>
                      <CatalogLink to='9'>Test Reports</CatalogLink>
                    </li>
                  </ul>
                </li>
                <li className={styles.depth1__item}>
                  <CatalogLink to='11'>APPLIED PRODUCTS</CatalogLink>
                  <ul className={styles.depth2}>
                    <li>
                      <CatalogLink to='11'>Aluminum Fastener</CatalogLink>
                    </li>
                    <li>
                      <CatalogLink to='13'>Metal 3D Printing Wire</CatalogLink>
                    </li>
                  </ul>
                </li>
                <li className={styles.depth1__item}>
                  <CatalogLink to='16'>APPLICATION SCOPE</CatalogLink>
                  <ul className={styles.depth2}>
                    <li>
                      <CatalogLink to='16'>
                        Flexibility of <br className='pc-br' />
                        Material Application
                      </CatalogLink>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </li>
          <li
            className={`${styles.gnb__item} ${
              currentPageData.depth1 === 'company' ? styles.active : ''
            }`}
          >
            <CatalogLink to='18'>Company</CatalogLink>
            <div className={`${styles.submenu} ${styles.auto}`}>
              <ul className={styles.depth1}>
                <li className={styles.depth1__item}>
                  <CatalogLink to='19'>PURPOSE AND VALUES</CatalogLink>
                </li>
                <li className={styles.depth1__item}>
                  <CatalogLink to='20'>ABOUT COLOR CUBE LABORATORY</CatalogLink>
                </li>
                <li className={styles.depth1__item}>
                  <CatalogLink to='21'>HISTORY OF COLOR CUBE</CatalogLink>
                </li>
              </ul>
            </div>
          </li>
        </ul>
        <nav className={styles.header__etc}>
          <a
            href='https://colorcube.kr/default/mp1/mp1_sub3.php?sub=05'
            target='_blank'
            className={styles.inq_btn}
          >
            <span>
              <span className={styles.m_hide}>ONLINE</span> INQUIRIES
            </span>
          </a>
          <ul className={styles.lang}>
            <li>
              <CatalogLink
                to={`/ko/${depth1}`}
                type='absolute'
              >
                KOR
              </CatalogLink>
            </li>
            <li className={styles.active}>
              <CatalogLink
                to={`/en/${depth1}`}
                type='absolute'
              >
                ENG
              </CatalogLink>
            </li>
          </ul>
          <div className={styles.ctrs}>
            <CatalogLink
              to='2'
              className={`${styles.ctrs__btn} ${styles.ctrs__idx}`}
            >
              <div className={styles.bar}></div>
              <div className={styles.bar}></div>
              <div className={styles.bar}></div>
            </CatalogLink>
            <AutoplayButton className={`${styles.ctrs__btn} ${styles.ctrs__autoplay}`} />
          </div>
        </nav>
      </header>
    );
  }
}

function Controller() {
  return (
    <nav className={styles.controller}>
      <PrevButton className={`${styles.btn} ${styles.prev}`}>
        <i className={`${styles.icon} arrow left`}></i>
      </PrevButton>
      <CatalogLink
        to='2'
        className={`${styles.idx} ${styles.btn}`}
      >
        <span></span>
        <span></span>
        <span></span>
      </CatalogLink>
      <NextButton className={`${styles.btn} ${styles.next}`}>
        <i className={`${styles.icon} arrow right`}></i>
      </NextButton>
    </nav>
  );
}

export default function Navigations() {
  return (
    <div className={styles.container}>
      <Header />
      <Controller />
    </div>
  );
}
